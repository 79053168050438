import 't-datepicker';
import 't-datepicker/public/theme/css/t-datepicker.min.css';
import 't-datepicker/public/theme/css/themes/t-datepicker-blue.css';


$(document).ready(function(){
    // Mendapatkan tanggal hari ini
    var today = new Date();
    var tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1); // Hari berikutnya

    // Fungsi untuk memformat tanggal menjadi 09 Oct 2024
    var formatDate = function(date) {
        var day = date.getDate().toString().padStart(2, '0');
        var month = date.toLocaleString('en-US', { month: 'short' }); // Nama bulan singkat (Oct)
        var year = date.getFullYear();
        return day + ' ' + month + ' ' + year; // Format 09 Oct 2024
    };

    // Inisialisasi tDatePicker dengan title check-in dan check-out dinamis
    $('.t-datepicker').tDatePicker({
        autoClose: false,
        durationArrowTop: 200,
        numCalendar: 2,
        iconDate: '',
        titleCheckIn: formatDate(today),     // Title check-in dengan tanggal hari ini
        titleCheckOut: formatDate(tomorrow) // Title check-out dengan tanggal hari berikutnya
    });
});


  
  const dropdownButton = document.getElementById('dropdown-button');
  const dropdownOptions = document.getElementById('dropdown-options');
  const hiddenInput = document.getElementById('selected-value'); // Input number for storing numeric value
  // Set initial default value
  let selectedValue = '1 Person'; // Default text for the button
  hiddenInput.value = 1; // Set the input value to default of 1
  // Toggle the dropdown visibility on button click
  dropdownButton.addEventListener('click', function() {
    dropdownOptions.classList.toggle('hidden');
  });
  // When an option is clicked
  dropdownOptions.addEventListener('click', function(e) {
    if (e.target.tagName === 'LI') {
        selectedValue = e.target.innerText; // Get the text from the clicked option
        const value = e.target.getAttribute('data-value'); // Get the numeric value from data-value
  
        // Set the button text and update the input number value
        dropdownButton.innerText = selectedValue;
        hiddenInput.value = value; // Directly change the input number value
  
        // Log to check if the input value is updated correctly
        console.log("Selected value:", hiddenInput.value);
  
        // Hide the options after selection
        dropdownOptions.classList.add('hidden');
    }
  });
  // Close the dropdown if clicked outside
  document.addEventListener('click', function(e) {
    if (!dropdownButton.contains(e.target)) {
        dropdownOptions.classList.add('hidden');
    }
  });